<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="justify-content-between align-items-center">
        <b-col sm="4">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">
            Carrousel
          </h1>
        </b-col>
        <!--  -->
        <b-col sm="4">
          <div class="custom-search d-flex justify-content-end">
            <b-button
              v-b-toggle.sidebar-1
              
              variant="primary"
              size="md"
            >
              <feather-icon icon="PlusIcon" />
              <span class="align-middle"> Ajouter Carrousel</span>
            </b-button>
          </div>
        </b-col>
        <b-sidebar
          id="sidebar-1"
          ref="sideBarCarousel"
          bg-variant="white"
          sidebar-class="sidebar-lg"
          shadow
          backdrop
          no-header
          right
        @hidden="hide"

        >
          <template>
            <div
              class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
            >
              <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
                Nouveau Carrousel
              </h5>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
            <validation-observer ref="carouselAdd">
              <b-form class="p-2" @reset.prevent="resetForm">
                <b-overlay :show="showLoading" no-wrap />
                <!-- -------------------------- -->
                <b-form-group label="URL" label-for="url">
                  <validation-provider
                    #default="{ errors }"
                    name="url"
                    rules=""
                  >
                    <b-form-input
                      class=""
                      v-model="rowData.url"
                      id="label"
                      placeholder="entre url"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                      >{{ errors[0] }}</b-form-invalid-feedback
                    >
                  </validation-provider>
                </b-form-group>
                <!-- -------------------------- -->
                <b-form-group class="mt-2" label-for="image">
                  <validation-provider
                    #default="{ errors }"
                    rules="required|image|dimensions:500,500"
                    name="image"
                  >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="btn-icon"
                          :variant="
                            errors.length > 0
                              ? 'outline-danger'
                              : 'outline-primary'
                          "
                          v-b-tooltip.hover="'Source de l\'image'"
                          @click="opencarousel"
                        >
                          <b-form-file
                            type="file"
                            id="openCarouselFile"
                            class="d-none"
                            v-model="rowData.path"
                          />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        placeholder="Source de l'image"
                        :value="rowData.path ? rowData.path.name : ''"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                      >{{ errors[0] }}</b-form-invalid-feedback
                    >
                  </validation-provider>
                </b-form-group>
                <!-- -------------------------- -->
                <b-form-group label="Description" label-for="description">
                  <!-- <div class="message-editor">
                    <quill-editor
                      id="description"
                      v-model="rowData.description"
                      :options="snowOption"
                    />
                  </div> -->
                  <b-form-textarea
      id="description"
      v-model="rowData.description"
     placeholder="écrire la description"
     
    ></b-form-textarea>
                </b-form-group>
                <!-- -------------------------- -->
                <b-form-group>
                  <b-form-checkbox
                    v-model="rowData.status"
                    inline
                    name="status"
                    switch
                  >
                    Activé
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group label="Assureur" label-for="assureur" v-if="currentUser.name == 'nidergane'">
                  <v-select v-model="rowData.assureur_id" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :getOptionKey="assureur => assureur.value + assureur.text" :options="assureursOptions" :reduce="assureur => assureur.value" label="text" placeholder="assureur">
                    <template #option="{ text }">
                      <span class="ml-50"> {{ text }} </span>
                    </template>
                    <div slot="no-options">Aucun assureur disponible.</div>
                  </v-select>
              </b-form-group>
                <!-- -------------------------- -->

                <b-button @click="handleSubmit" variant="primary" size="md">
                  <span class="align-middle"> Ajouter Carrousel</span>
                </b-button>
              </b-form>
            </validation-observer>
          </template>
        </b-sidebar>
      </b-row>
      <b-sidebar
        id="sidebar-2"
        ref="sideBarCarouselModifer"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="hide"
      >
        <template>
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
              Modifier Carrousel
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
          <validation-observer ref="carouselEdit">
            <b-form class="p-2" @reset.prevent="resetForm">
              <b-overlay :show="showLoading" no-wrap />

              <!-- -------------------------- -->

              <b-form-group label="URL" label-for="url">
                <validation-provider #default="{ errors }" name="url" rules="">
                  <b-form-input
                    class=""
                    v-model="rowData.url"
                    id="label"
                    placeholder="entre url"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                    >{{ errors[0] }}</b-form-invalid-feedback
                  >
                </validation-provider>
              </b-form-group>
              <!-- -------------------------- -->
              <b-form-group class="mt-2" label-for="image">
                <validation-provider
                  #default="{ errors }"
                  rules=""
                  name="image|dimensions:100,100"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="btn-icon"
                        :variant="
                          errors.length > 0
                            ? 'outline-danger'
                            : 'outline-primary'
                        "
                        v-b-tooltip.hover="'Source de l\'image'"
                        @click="opencarousel"
                      >
                        <b-form-file
                          type="file"
                          id="openCarouselFile"
                          class="d-none"
                          v-model="rowData.path"
                        />
                        <feather-icon icon="UploadCloudIcon" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      disabled
                      placeholder="Source de l'image"
                      :value="rowData.path ? rowData.path.name : ''"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-muted">{{ rowData.path_name }}</small>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                    >{{ errors[0] }}</b-form-invalid-feedback
                  >
                </validation-provider>
              </b-form-group>
              <!-- -------------------------- -->
              <b-form-group label="Description" label-for="description">
                <b-form-textarea
      id="description"
      v-model="rowData.description"
     placeholder="écrire la description"
     
    ></b-form-textarea>
              </b-form-group>
              <!-- -------------------------- -->
              <b-form-group>
                <b-form-checkbox
                  v-model="rowData.status"
                  inline
                  name="status"
                  switch
                >
                  Activé
                </b-form-checkbox>
              </b-form-group>
              <!-- -------------------------- -->
              <b-button @click="modifierCarousel()" variant="primary" size="md">
                <span class="align-middle"> Modifer Carrousel</span>
              </b-button>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>

    <b-overlay :show="showLoading" no-wrap />
    <vue-good-table
      style-class="vgt-table condensed"
      class="custom-table-bottom"
            @on-selected-rows-change="selectionChanged"

      :columns="carouselColumns"
      :rows="carouselsRows"
      :rtl="direction"
      :search-options="{
        placeholder: 'Rechercher',
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: (params) => ``,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'lignes sélectionnées',
        clearSelectionText: 'Effacer la sélection',
        disableSelectInfo: false,
        selectAllByGroup: false,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.label === 'Actions'">
          <span>
            <b-dropdown
              no-caret
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle mr-25"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item
                v-b-toggle.sidebar-2
                @click="
                  functionid(
                    props.row.id,
                    props.row.url,
                    props.row.path_name,
                    props.row.description,
                    props.row.status == 'ACTIF' ? true : false
                  )
                "
              >
                <feather-icon size="20" icon="EditIcon" class="mr-50" />

                <span>Modifier</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                @click="deleteCarousel(props.row.id)"
              >
                <feather-icon class="mr-50" icon="TrashIcon" />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- Slice Description and Url cause Too Long -->
        <!-- Description -->
        <span v-else-if="props.column.field == 'description'">
          <span v-if="!props.row.description || props.row.description == 'null'">-</span>
          <span
            v-else-if="
              props.row.description && props.row.description.length > 33
            "
            v-b-tooltip.hover
            :title="props.row.description"
            >{{ props.row.description.slice(0, 34) }}...</span
          >
          <span v-else >{{props.row.description}}</span>
        </span>
        <!-- URL -->
        <span v-else-if="props.column.field == 'url'">
          <b-link href="#" v-if="!props.row.url || props.row.url=='null'">-</b-link>

          <b-link
            :href="props.row.url"
            v-else-if="props.row.url && props.row.url.length > 40"
            v-b-tooltip.hover
            :title="props.row.url"
            >{{ props.row.url.slice(0, 41) }}...</b-link
          >
          <b-link :href="props.row.url" v-else>{{ props.row.url }}</b-link>
        </span>
        <!-- Status Badge -->
        <span v-else-if="props.column.field == 'status'">
          <b-badge v-if="props.row.status == 'ACTIF'" variant="light-success">{{
            props.row.status
          }}</b-badge>
          <b-badge v-else variant="light-danger">{{
            props.row.status
          }}</b-badge>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
          <b-col md="6" lg="5" xxl="3">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Affichage 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> de {{ props.total }} entrées </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-center mb-4">
          <b-col>
            <b-input-group>
              <b-form-select v-model="selected_action" :options="options" />
              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="action_masse(selected_action)"

                >
                  <span>Valider </span>
                  <feather-icon class="mr-50" icon="ArrowRightIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import { required } from "@validations";
import { image,dimensions } from "vee-validate/dist/rules";

// Install rules from veevalidate
extend("image", image);
extend("dimensions", dimensions);
import {
  BInputGroupAppend,
  BFormSelectOption,
  VBTooltip,
  BFormInvalidFeedback,
  BInputGroupPrepend,
  BFormFile,
  BFormInput,
  BFormGroup,
  BPagination,
  BForm,
  VBToggle,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardText,
  BCardHeader,
  BMedia,
  BImg,
  BOverlay,
  BFormSelect,
  BSidebar,
  BFormTextarea,
  BInputGroup,
  BTooltip,
  BFormCheckbox,
  BLink,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import { VueGoodTable } from "vue-good-table";
import vSelect from 'vue-select'

import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BBadge,
    BLink,
    BTooltip,
    BFormCheckbox,
    BFormSelectOption,
    BFormInvalidFeedback,
    VBToggle,
    vSelect,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    BFormGroup,
    BForm,
    BInputGroupAppend,
    BFormTextarea,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BSidebar,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ToastificationContentVue,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem("userData")),
      errors: [],
      pageLength: 10,
      carouselsRows: [],
      assureursOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      carouselColumns: [
       
        {
          label: "Identifiant",
          field: "id",
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking",
          tdClass: "text-center m-0 p-2 align-middle",
        },
        {
          label: "URL",
          field: "url",
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking",
          tdClass: "text-center m-0 p-2 align-middle",
        },
        {
          label: "Description",
          field: "description",
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking",
          tdClass: "text-center m-0 p-2 align-middle",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking",
          tdClass: "text-center m-0 p-2 align-middle",
        },
        {
          label: "Actions",
          field: "id",
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking",
          tdClass: "text-center m-0 p-2 align-middle",
        },
        {
          label: "",
          field: "path_name",
          sortable: false,
          hidden: true,
          thClass: "",
          tdClass: "",
        },
      ],
      rowData: {
        id: null,
        url: "",
        description: "",
        status: false,
        path: "",
        path_name: "",
        assureur_id: null
      },
      
      
      options: [
        {
          value: null,
          text: "Choisissez",
        },
       {
          value: 'Supprimer',
          text: 'Supprimer'
        },
      ],
      selected_action: null,
      selectedrows_masse : [],

    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.fetchAssureurList()
    this.fetchCarousels();

  },
  methods: {
    fetchAssureurList() {
      this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
        .post('/assureurs/fetchAssureurList')
        .then(res => {
          if (res.data.success) {
            res.data.data.forEach(el => {
              this.assureursOptions.push({
                value: el.id,
                text: el.nom_com_assureur,
              })
            })            
          }
        })
        .catch(err => {
          this.assureursOptions = [{
            value: null,
            text: '-- Choisissez --'
          }]
          console.error(err)
        })
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    fetchCarousels() {
      this.showLoading = true;
      this.carouselsRows = [];
      this.$http
        .get("/carousels")
        .then((res) => {
          if (res.data.success) {
            this.carouselsRows = res.data.data;
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.messageToast(
              "Nous n'avons pas pu trouver la ressource que vous avez demandée.",
              "Erreur",
              "warning"
            );
          }
          console.log(res.data);
        })
        .catch((err) => {
          this.showLoading = false;
          this.messageToast(err.response.data.errors[0], "Erreur", "error");
          console.error(err);
        });
    },
  
    handleSubmit() {
      console.log("handle");
      console.log(this.rowData);
      this.$refs.carouselAdd.validate().then((success) => {
        if (success) {
          const formData = new FormData();
          this.showLoading = true;
          formData.append("url", this.rowData.url);
          formData.append("description", this.rowData.description);
          formData.append("status", this.rowData.status);
          formData.append("path", this.rowData.path);
          console.log(this.rowData.assureur_id)
          if(this.rowData.assureur_id && this.rowData.assureur_id != null && this.rowData.assureur_id != 'null' )
            formData.append("assureur_id", this.rowData.assureur_id );

          const config = { headers: { "Content-Type": "multipart/form-data" } };
          this.$http
            .post("/carousels/add-carousel", formData, config)
            .then((res) => {
              if (res.data.success) {
                this.hide();
                this.fetchCarousels();
                this.showLoading = false;
                this.messageToast(
                  "Carousel a été Bien ajouter",
                  "Succès",
                  "success"
                );
              } else {
                this.showLoading = false;
                this.messageToast(
                  "Erreur lors de creation de nouveau carousel.",
                  "Erreur",
                  "error"
                );
              }
            })
            .catch((err) => {
              this.showLoading = false;
              this.messageToast(err.response.data.errors[0], "Erreur", "error");
            });
        }
      });
    },
    modifierCarousel() {
      const formData = new FormData();
      this.$refs.carouselEdit.validate().then((success) => {
        if (success) {
          this.showLoading = true;
          formData.append("id", this.rowData.id);
          formData.append("url", this.rowData.url);
          formData.append("description", this.rowData.description);
          formData.append("status", this.rowData.status);
          formData.append("path", this.rowData.path);
          const config = { headers: { "Content-Type": "multipart/form-data" } };
          this.$http
            .post("/carousels/update-carousel", formData, config)
            .then((res) => {
              if (res.data.success) {
                this.hide();
                this.fetchCarousels();
                this.showLoading = false;
                this.messageToast(
                  "Carousel a été Bien modifier",
                  "Succès",
                  "success"
                );
              } else {
                this.showLoading = false;
                this.messageToast(
                  "Erreur lors de modification de nouveau carousel.",
                  "Erreur",
                  "error"
                );
              }
            })
            .catch((err) => {
              this.showLoading = false;
              this.messageToast(err.response.data.errors[0], "Erreur", "error");
            });
        }
      });
    },
    deleteCarousel(id) {
      this.$swal({
        title: "Etes-vous sûr de vouloir supprimer ce carrousel ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log(id);

          const formData = new FormData();

          this.showLoading = true;

          formData.append("id", id);

          this.$http
            .post("/carousels/delete-carousel", formData)
            .then((res) => {
              if (res.data.success) {
                this.fetchCarousels();
                this.showLoading = false;
                this.messageToast(
                  "Carousel a été Bien supprimer",
                  "Succès",
                  "success"
                );
              }
            })
            .catch((err) => {
              this.showLoading = false;
              this.messageToast(err.response.data.errors[0], "Erreur", "error");
            });
        }
      });
    },
    hide() {
      this.$refs.sideBarCarousel.hide();
      this.$refs.sideBarCarouselModifer.hide();
      this.clearData();
    },
    clearData() {
      this.$refs.carouselEdit.reset();
      this.$refs.carouselAdd.reset();
      this.rowData.id = null;
      this.rowData.url = "";
      this.rowData.path = "";
      this.rowData.path_name = "";
      this.rowData.status = false;
      this.rowData.description = "";
    },
    functionid(id, url, path, description, status) {
      if(url=='null'){
        url = ''
      }
      if(description == 'null'){
        description = '';
      }
      this.rowData.id = id;
      this.rowData.url = url;
      this.rowData.path_name = path;
      this.rowData.status = status;
      this.rowData.description = description;
    },
    opencarousel: () => {
      document.getElementById("openCarouselFile").click();
    },
    action_masse(action){
      switch (action) {
        case 'Supprimer':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: "Êtes-vous sur de vouloir supprimer le/les carrousel sélectionnés ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$http
                  .post("carousels/delete-carousel-multiple/",{'carousels' : this.selectedrows_masse.selectedRows})
                  .then((res) => {
                    if (res.data.success) {
                      for(let i=0;i<this.carouselsRows.length;i++)
                      {
                        for(let j=0;j<res.data.data.length;j++)
                        {
                          if(this.carouselsRows[i].id == res.data.data[j].id)
                          {
                            this.carouselsRows.splice(i, 1)
                          }
                        }
                      }
                    }
                  })
                  .catch((err) => {});

                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          }
          break;
        default:

      }
    },
   selectionChanged(params) {
      this.selectedrows_masse = params
      this.selected_action = null
      if(this.selectedrows_masse.selectedRows.length > 0) {
       //
      }
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
* div.custom-table-bottom > div > .vgt-responsive {
  min-height: 0 !important;
}
</style>
